import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MySharedModule } from '../../../shared/my-shared.module';
import { AuthService } from '../../../auth/services/auth.service';
import { UnidadeService } from '../../../servidor/services/unidade.service';
import { Subscriptions } from '../../../../types/system.types';
import { Unidade } from '../../../../types/servidor.types';
import { TreeTableNode } from 'primeng/api';
import { Column } from '../../../servidor/components/usuario/usuario-data-grid/usuario-data-grid.component';

@Component({
	selector: 'stj-unidade-btn-select-tree',
	standalone: true,
	imports: [
		MySharedModule,
	],
	providers: [
		AuthService,
		UnidadeService,
	],
	templateUrl: './unidade-btn-select-tree.component.html',
	styleUrl: './unidade-btn-select-tree.component.scss'
})
export class UnidadeBtnSelectTreeComponent implements OnInit, AfterContentInit, OnDestroy
{
	constructor (
		private authService: AuthService,
		private unidadeService: UnidadeService,
	) { }

	loading: boolean = false;
	subs: Subscriptions = {};
	unidades: Unidade[] = [];
	unidadesFlat: Unidade[] = [];
	unidadePai?: Unidade;
	selectedUnidade?: Unidade;
	selectedUnidadeTree: TreeTableNode | null = null;
	minhasUnidades: any[] = [];
	minhasUnidadesTree: TreeTableNode<Unidade>[] = [];
	colunasUnidades: Column[] = [];
	sidebarVisible: boolean = false;

	@Input('unidadeId') unidadeId?: number;
	@Output('ngModelChange') changeEmmitter: EventEmitter<Unidade> = new EventEmitter<Unidade>();

	ngOnInit(): void
	{
		this.configuraColunas();
	}

	ngAfterContentInit(): void
	{
		this.subs['unidades'] = this.authService.unidades.subscribe((unidades) =>
		{
			this.unidades = unidades;
			this.unidadePai = unidades.length ? unidades[0] : undefined;
			this.carregaUnidades();
		});
	}

	ngOnDestroy(): void
	{
		if (this.subs) 
		{
			for (let s in this.subs)
			{
				this.subs[s].unsubscribe();
			}
		}
	}

	async carregaUnidades()
	{
		let unidadesTree: any;

		if (this.authService.currentUser.value === undefined)
		{
			this.minhasUnidades = [];
			this.unidadesFlat = [];
			return;
		}
		const isAdmin = await this.authService.hasRole(['admin.all']);
		if (isAdmin)
		{
			unidadesTree = await this.unidadeService.pegaFilhasTree(1);
			if (unidadesTree) unidadesTree = unidadesTree.filhos;
		}
		else if (this.unidadePai?.id_unidade)
		{
			unidadesTree = await this.unidadeService.pegaFilhasTree(this.unidadePai?.id_unidade);
			unidadesTree = unidadesTree ? [unidadesTree] : []
		}
		else
		{
			unidadesTree = [];
		}

		this.minhasUnidades = unidadesTree as any[];
		let unidadesFlat: Unidade[] = [];
		let { flat, tree } = this.treeTransform(unidadesTree, unidadesFlat);
		this.minhasUnidadesTree = tree;
		this.unidadesFlat = flat;
		let unidade = this.unidadesFlat.find(uni => uni.id_unidade == this.unidadeId);
		this.selectedUnidade = unidade ? unidade : this.unidadePai;
		if (this.selectedUnidade) this.disparaEvento();
	}

	async disparaEvento()
	{
		this.changeEmmitter.emit(this.selectedUnidade);
	}

	async configuraColunas()
	{
		this.colunasUnidades = [
			{
				header: 'Sigla',
				field: 'sigla_unidade',
			},
			{
				header: 'Nome',
				field: 'nome_unidade',
			},
		];
	}

	treeTransform(unidades: Unidade[], unidadesFlat: Unidade[], parent?: Unidade): { flat: Unidade[], tree: TreeTableNode<Unidade>[] }
	{
		if ((unidades?.length ?? 0) == 0) return { flat: [], tree: [] };

		let saida: TreeTableNode<any>[] = [];
		// console.log(this.unidadeId);
		for (let unidade of unidades)
		{
			let item: TreeTableNode<any> = {
				label: unidade.sigla_unidade,
				data: unidade,
				key: 'sigla_unidade',
			}
			unidadesFlat.push(item.data);
			if (unidade.filhos)
			{
				let data = this.treeTransform(unidade.filhos, unidadesFlat, unidade);
				item.children = data.tree;
				unidadesFlat.push(...unidade.filhos);
			}
			if (item.data.id_unidade == this.unidadeId)
			{
				this.selectedUnidadeTree = item;
			}
			else if (!this.unidadeId && (item.label == this.unidadePai?.sigla_unidade))
			{
				this.selectedUnidadeTree = item;
			}
			saida.push(item);
		}
		return { flat: unidadesFlat, tree: saida };
	}

	abrePainelUnidade()
	{
		this.sidebarVisible = true;
	}

	onSelectUnidade(row: any)
	{
		this.sidebarVisible = false;
		// row.node.selected = true;
		this.selectedUnidadeTree = row?.node;
		this.selectedUnidade = row?.node?.data;
		this.disparaEvento();
	}

	filtraItems(tt: any, col: any, event: any)
	{
		tt.filter(event.currentTarget?.value, col.field, col.filterMatchMode)
	}

}
