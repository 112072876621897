<p-sidebar [(visible)]="sidebarVisible" [style]="{'width': 'max(320px, 50vw)'}" appendTo="body">
	<p-treeTable
		#tt
		[value]="minhasUnidadesTree"
		[columns]="colunasUnidades"
		sortMode="multiple"
		selectionMode="single"
		dataKey="id_unidade"
		[scrollable]="true"
		[(selection)]="selectedUnidadeTree"
		[filterMode]="'lenient'"
		[loading]="unidades.length == 0">
		<ng-template pTemplate="caption">
			<label for="">Unidade (sigla): </label>
			<input title="Filtrar por sigla da unidade" pInputText type="text" (input)="filtraItems(tt, colunasUnidades[0], $event)" />
		</ng-template>
		<ng-template pTemplate="header" let-cols>
			<tr>
				<th *ngFor="let col of cols">
					{{col.header}}
				</th>
				<th></th>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-row let-item="rowData" let-cols="columns">
			<tr [ttRow]="row" [ttSelectableRow]="row">
				<td *ngFor="let col of cols; let i = index">
					<p-treeTableToggler [rowNode]="row" *ngIf="i == 0"></p-treeTableToggler>
					{{(item && col.field) ? item[col.field] : ''}}
				</td>
				<td class="opcoes">
					<p-button icon="pi pi-send" pTooltip="Selecionar unidade" (onClick)="onSelectUnidade(row)"></p-button>
				</td>
			</tr>
		</ng-template>
	</p-treeTable>
</p-sidebar>
<p-button *ngIf="minhasUnidades.length" icon="pi pi-sitemap" [label]="selectedUnidade ? ('Unidade: '+selectedUnidade.sigla_unidade) : 'Selecione a unidade'" (onClick)="abrePainelUnidade()"></p-button>