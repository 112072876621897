import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Unidade } from '../../../types/servidor.types';
import { DefaultService } from '../../shared/services/-default.service';
import { MyMessageService } from '../../shared/services/my-message.service';

@Injectable({
	providedIn: 'root'
})
export class UnidadeService extends DefaultService
{
	constructor (
		http: HttpClient,
		private msgService: MyMessageService,
	)
	{
		super(http);
	}

	async pegaFilhasTree(unidadePai: number = 1)
	{
		let url = new URL(window.location.origin + `/api-sust/intranet/unidade/${unidadePai}/arvore`);
		
		let dados: Unidade = {};
		try {
			dados = await this._genericGet(url.href);
		} catch (error: any) {
			this.msgService.add({severity: 'error',summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}`})
			// console.log(error);
		} finally {
			return dados as Unidade;
		}
	}

	async pegaFilhasFlat(unidadePai: number = 1)
	{
		let url = new URL(window.location.origin + `/api-sust/intranet/unidade/${unidadePai}/filhos`);
		
		let dados: Unidade[];
		try {
			dados = await this._genericGet(url.href) as Unidade[];
		} catch (error: any) {
			dados = [];
			this.msgService.add({severity: 'error',summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}`})
			// console.log(error);
		} finally {
			return dados! as Unidade[];
		}
	}
	
	async extraiIntranet(salvar?: boolean, id_unidade?: number)
	{
		let url = new URL(window.location.origin + `/api-sust/intranet/extrator`);
		url.searchParams.set('salvar', (salvar ? '1' : '0'));
		if (id_unidade) url.searchParams.set('id_unidade', (id_unidade).toString());
		
		let dados: Unidade = {};
		try {
			dados = await this._genericGet(url.href);
		} catch (error: any) {
			this.msgService.add({severity: 'error',summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}`})
			// console.log(error);
		} finally {
			return dados as Unidade;
		}
	}
	
	async pegaInfoExtracao()
	{
		let url = new URL(window.location.origin + `/api-sust/intranet/extrator/info`);
		// url.searchParams.set('salvar', (salvar ? '1' : '0'));
		// if (id_unidade) url.searchParams.set('id_unidade', (id_unidade).toString());
		
		let dados: Unidade = {};
		try {
			dados = await this._genericGet(url.href);
		} catch (error: any) {
			this.msgService.add({severity: 'error',summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}`})
			// console.log(error);
		} finally {
			return dados as any[];
		}
	}
}
